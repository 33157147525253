<template>
  <TopBar :coupons="availableCoupons" />
  <div class="wrapper">
    <div class="shop-wrapper">
      <h1 class="text-center">Zet je loten in & win</h1>
      <p class="text-center">
        Wauw! je hebt {{ coupons }} loten verzameld.
        <br />
        Op welke prijzen wil jij kans maken? klik op '+'
      </p>
      <div v-for="prize in prizeCategories" class="priority-card">
        <div class="image-wrapper">
          <img :src="prize.img" />
        </div>
        <div class="content">
          <div class="title">
            <h2 v-html="prize.title" />
            <p v-html="prize.subTitle" />
          </div>
          <div class="actions">
            <button @click="openMoreInfo(prize.id)" class="btn btn-outline-sm">Bekijk</button>
            <InputCoupon
              v-model="distribution[prize.id]"
              :available="availableCoupons"
              :limit="coupons"
            />
          </div>
        </div>
      </div>

      <div class="button-wrapper">
        <button :disabled="!isValid" @click="submitCoupons" class="btn btn-primary">
          Bevestigen
        </button>
      </div>
      <div class="terms-link-wrapper">
        <a class="terms-link" :href="$store.terms" target="_blank">Actievoorwaarden</a>
      </div>
    </div>
  </div>
  <transition
    enter-active-class="animate__animated animate__faster animate__slideInUp"
    leave-active-class="animate__animated animate__faster animate__slideOutDown"
    mode="out-in"
  >
    <div v-if="isValid" class="all-filled-footer">
      <p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18.828"
          height="13.414"
          viewBox="0 0 18.828 13.414"
        >
          <path
            id="check_51_"
            data-name="check (51)"
            d="M20,6,9,17,4,12"
            transform="translate(-2.586 -4.586)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
        Je hebt al je loten gebruikt
      </p>
      <button :disabled="!isValid" @click="submitCoupons" class="btn btn-primary">
        Nu inzetten
      </button>
    </div>
  </transition>
  <transition
    enter-active-class="animate__animated animate__faster animate__slideInUp"
    leave-active-class="animate__animated animate__faster animate__slideOutDown"
    mode="out-in"
  >
    <div v-if="showInfoWindow" class="info-window">
      <img class="close" @click="closeMoreInfo()" src="@/assets/images/x-circle.svg" />
      <div class="trophy-wrapper">
        <img v-if="showInfoWindow" class="trophy" :src="moreInfoContent.imgFull" />
      </div>
      <div class="content-holder">
        <div v-for="prize in moreInfoContent.prizes" class="priority-card darker">
          <div class="content">
            <h2 v-html="prize.title" />
            <p v-html="prize.description" />
          </div>
        </div>
        <footer>
          <a class="terms-link" :href="$store.terms" target="_blank">Actievoorwaarden</a>
          <InputCoupon
            v-model="distribution[moreInfoContent.id]"
            :available="availableCoupons"
            :limit="coupons"
            :enlarged="true"
          />
        </footer>
      </div>
    </div>
  </transition>
  <transition
    enter-active-class="animate__animated animate__faster animate__fadeIn"
    leave-active-class="animate__animated animate__faster animate__fadeOut"
    mode="out-in"
  >
    <div v-if="showInfoWindow" @click="closeMoreInfo" class="overlay"></div>
  </transition>
</template>

<script setup>
import TopBar from '@/components/TopBar.vue'
import InputCoupon from '@/components/InputCoupon.vue'
</script>

<script>
export default {
  data() {
    return {
      showInfoWindow: false,
      moreInfoId: false
    }
  },
  created() {},
  mounted() {},
  computed: {
    availableCoupons() {
      let availableCoupons = this.coupons
      for (const [key, value] of Object.entries(this.distribution)) {
        availableCoupons -= value
      }
      return availableCoupons
    },
    prizeCategories() {
      return this.$store.prizeCategories
    },
    earnedCoupons() {
      return this.$store.gameData.coupons
    },
    coupons() {
      return this.earnedCoupons + this.previousPlayedSessions
    },
    distribution: {
      get() {
        return this.$store.userInput.couponDistribution
      },
      set(value) {
        this.$store.userInput.couponDistribution = value
      }
    },
    isValid() {
      return this.availableCoupons === 0
    },
    previousPlayedSessions() {
      return this.$store.player.previousPlayedSessions
    },
    moreInfoContent() {
      if (this.moreInfoId) {
        return this.prizeCategories.find((obj) => {
          return obj.id === this.moreInfoId
        })
      }
      return false
    }
  },
  methods: {
    submitCoupons() {
      this.$store.submitCoupons()
      if (this.$store.player.optin.msisdn) {
        this.$router.push({ name: 'end' })
      } else {
        this.$router.push({ name: 'opt-in' })
      }
    },
    openMoreInfo(id) {
      this.moreInfoId = id
      this.showInfoWindow = true
    },
    closeMoreInfo() {
      this.moreInfoId = false
      this.showInfoWindow = false
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.shop-wrapper {
  margin-top: 20px;

  .priority-card {
    padding: 0;

    .image-wrapper {
      width: 140px;

      img {
        display: block;
        width: 110%;
        height: 110%;
        transform: translate(-10%, -10%);
        max-width: 105px;
      }
    }
    .content {
      padding: 0 10px;
      width: 100%;

      p {
        font-size: 14px;
      }

      .actions {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
      }
    }
  }

  .button-wrapper {
    margin: 40px;
  }

  .terms-link-wrapper {
    margin: 40px;
    padding-bottom: 170px;
    text-align: center;
  }
}

.info-window {
  .content {
    padding: 15px 20px;
  }
}

.all-filled-footer {
  position: fixed;
  bottom: 0;
  padding: 20px 20px 80px 20px;
  text-align: center;
  background: #1a2129;
  width: 100%;
  z-index: 500;
  border-top: solid 1px #39414a;
}
</style>
