<template>
  <TopBar />
  <div class="wrapper">
    <div class="ending-wrapper">
      <h1 class="text-center">Spannend!</h1>
      <p class="text-center">
        De trekking vindt plaats begin januari 2025. <br />Je krijgt bericht van ons.
      </p>
      <template v-for="(coupons, prizeId) in couponDistribution">
        <div v-if="coupons > 0" class="priority-card">
          <div class="content">
            <div class="coupons">
              <img src="@/assets/images/coupon.svg" />
              {{ coupons }}x
            </div>
            <div class="title">
              <h2 v-html="getPrizeDetailsById(prizeId).title" />
              <p v-html="getPrizeDetailsById(prizeId).subTitle" />
            </div>
          </div>
          <footer>
            <div>Bekijk alle <span v-html="getPrizeDetailsById(prizeId).title" /></div>
            <button
              @click="openUrl(getPrizeDetailsById(prizeId).categoryUrl)"
              class="btn btn-outline-sm"
            >
              Bekijk
            </button>
          </footer>
        </div>
      </template>
      <img class="stacked-lot" src="@/assets/images/stacked-lot.png" />
      <h1 class="text-center">
        Verdien elke maand<br />
        een extra lot!
      </h1>
      <p class="text-center">
        Speel je de volgende ronde weer mee? Dan kun je een extra lot winnen. En dat elke ronde
        weer!
      </p>
      <div class="button-wrapper">
        <button @click="backToPriority()" class="btn btn-primary">Terug naar Priority</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import TopBar from '@/components/TopBar.vue'
</script>

<script>
export default {
  data() {
    return {
      showInfoWindow: false,
      moreInfoId: false
    }
  },
  created() {},
  mounted() {},
  computed: {
    prizeCategories() {
      return this.$store.prizeCategories
    },
    couponDistribution() {
      return this.$store.userInput.couponDistribution
    },
    moreInfoContent() {
      if (this.moreInfoId) {
        return this.prizeCategories.find((obj) => {
          return obj.id === this.moreInfoId
        })
      }
      return false
    }
  },
  methods: {
    backToPriority() {
      window.location.replace(this.$store.player.backUrl)
    },
    getPrizeDetailsById(id) {
      return this.prizeCategories.find((obj) => {
        return obj.id === id
      })
    },
    openUrl(url) {
      window.location.replace(url)
    }
  },

  watch: {}
}
</script>

<style lang="scss" scoped>
.ending-wrapper {
  margin-top: 40px;
  padding: 0 40px 120px 40px;

  .priority-card {
    flex-direction: column;
    padding-bottom: 10px;

    .content {
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: 10px;
      justify-content: start;

      .coupons {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        padding: 5px 8px;
        font-size: 16px;

        img {
          margin-right: 5px;
          height: 18px;
        }
      }
    }

    footer {
      display: flex;
      justify-content: space-between;
      border-top: solid 1px #515860;
      margin: 0 -10px;
      padding: 10px 20px 0 20px;
      align-items: center;
    }
  }

  .stacked-lot {
    width: 100%;
  }

  .button-wrapper {
    margin-top: 20px;
  }

  .terms-link-wrapper {
    margin: 40px 0;
    text-align: center;
  }
}
</style>
